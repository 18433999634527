import React, { useState } from 'react';
import { CardModal, H5, P, Button } from '@rentecarlo/component-library';
import { Status } from 'assets';
import { Spacer, TextContent, Content, IncedentIconContainer, StatusIcon } from './assets/styles';

import QuoteDateTimeInput from '../DateTimeInput/AccountDateTimeInput';

interface Props {
  makeClaim: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, date: string) => void;
  visible: boolean;
  error: boolean;
  height: string;
  close: () => void;
}

const CaptureIncidentDate: React.FC<Props> = ({ visible, error, close, makeClaim, height }) => {
  const [date, setDate] = useState('');
  const [returnedDate, setReturnedDate] = useState('');
  const [returnedTime, setReturnedtime] = useState('');
  const handleFinalDateTime = (newDate: string, newTime: string) => {
    setReturnedDate(newDate);
    setReturnedtime(newTime);
    const newDateTime = `${returnedDate} ${returnedTime}`;
    setDate(newDateTime);
  };

  return (
    <CardModal visible={!!visible || !!error} maxWidth='343px' height={height} close={close}>
      <Content>
        <TextContent id='date-time-component'>
          <H5 lineHeight={25}>When did the incident happen?</H5>
          <Spacer />
          <QuoteDateTimeInput
            onChange={handleFinalDateTime}
            dateId='fromDate'
            timeId='fromTime'
            calenderIconId='fromCalendarIcon'
          />
          <IncedentIconContainer>
            <StatusIcon src={Status} />
            <P fontSize={14}>Please provide the estimated date and time of your incident.</P>
          </IncedentIconContainer>
          <Spacer height={16} />
          <Button
            id='start-claim-self-serve'
            borderWidth={2}
            onClick={(event) => {
              makeClaim(event, date);
              close();
            }}
            height={64}
            borderRadius={8}
            type='button'
            disabled={!returnedDate || !returnedTime}
          >
            Next
          </Button>
        </TextContent>
      </Content>
    </CardModal>
  );
};

export default CaptureIncidentDate;
