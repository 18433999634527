import React from 'react';
import { PageModal, H1, H5, Button, IconList } from '@rentecarlo/component-library';
import { Status } from 'assets';
import {
  Container,
  ButtonsContainer,
  ButtonContainer,
  SubheadingContainer,
  Content,
  Text,
  SubText,
} from './assets/styles';

type Props = {
  show: boolean;
  close: () => void;
  startRepurchaseJourney: () => void;
};

const ChangeVehicleSubsInfoModal: React.FC<Props> = ({ show, close, startRepurchaseJourney }) => {
  const redirectToMTAHelpCentre = () => {
    const win = window.open('', '_blank');
    if (win) {
      win.location.href = 'https://www.veygo.com/help-centre/';
      win.focus();
    }
    close();
  };

  const startRepurchaseJourneyAndClose = () => {
    startRepurchaseJourney();
    close();
  };

  return (
    <PageModal
      show={show}
      close={() => {
        close();
      }}
      closeText='Back'
    >
      {show && (
        <Container>
          <H1>Change your vehicle</H1>
          <SubheadingContainer>
            <H5>We can get you on the road again in minutes!</H5>
          </SubheadingContainer>
          <Content>
            <SubText>
              Policy changes with Veygo look a bit different. Here's what you need to know:
            </SubText>
            <IconList
              id='start-claim-info-list'
              icon={Status}
              items={[
                <Text>You'll need to repurchase a new policy</Text>,
                <Text>
                  Your payment date may change - please check your new policy details when you
                  complete the quote
                </Text>,
                <Text>
                  We'll cancel your current policy when you've repurchased. You'll get up to{' '}
                  <b>£30.00</b>* credit for the time left on your current policy now to help pay for
                  your new cover.
                </Text>,
              ]}
            />
          </Content>
          <ButtonsContainer>
            <ButtonContainer>
              <Button
                id='learn-more'
                borderWidth={0.5}
                height={64}
                borderRadius={8}
                type='button'
                buttonType='outline'
                onClick={redirectToMTAHelpCentre}
              >
                Learn more
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button
                id='start-repurchase-journey'
                borderWidth={2}
                onClick={startRepurchaseJourneyAndClose}
                height={64}
                borderRadius={8}
                type='button'
              >
                Requote with credit
              </Button>
            </ButtonContainer>
          </ButtonsContainer>
          <Content>
            <SubText>
              *This credit amount is a guide and can change depending on when you start your quote.
              We'll confirm the credit amount on the next page.
            </SubText>
          </Content>
        </Container>
      )}
    </PageModal>
  );
};
export default ChangeVehicleSubsInfoModal;
